import store from '@/store'

function checkPermission(el, binding) {
  const { value } = binding
  const btnPermissions = store.getters && store.getters.btnPermissions

  if (value) {
    if (typeof value ==='string' && value.trim()) {
      const permissionCode = value.trim()

      const hasPermission = btnPermissions.some(v => {
        return permissionCode === v
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  } else {
    throw new Error(`need permission values! Like v-permission="'look_btn'"`)
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}
