// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#8593A7",
	"menuActiveText": "#2262FB",
	"subMenuActiveText": "#8593A7 100%",
	"menuBg": "transparent",
	"menuHover": "transparent",
	"subMenuBg": "transparent",
	"subMenuHover": "transparent",
	"sideBarWidth": "210px"
};
module.exports = exports;
