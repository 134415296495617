import { asyncRoutes, constantRoutes } from '@/router'
import { getPermissionInfos } from '@/api/api'

function hasPermission(permissions, route) {
  if (route.permissionCode) {
    return permissions.some(item => item.permissionCode.includes(route.permissionCode))
  } else {
    return true
  }
}

export function filterAsyncRoutes(routes, permissions) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })

  return res
}

export function genPermissionList(permissions) {
  const menus = []
  const btnPermissions = []

  permissions.forEach(item => {
    if (item.type === 3) {
      btnPermissions.push(item.permissionCode)
    } else {
      const { id, name, permissionCode, type } = item
      menus.push({id, name, permissionCode, type})
    }
    if (item.sonDTOs && item.sonDTOs.length > 0) {
      const result = genPermissionList(item.sonDTOs)
      btnPermissions.push(...result.btnPermissions)
      menus.push(...result.menus)
    }
  })

  return {
    btnPermissions,
    menus
  }
}

const state = {
  routes: [],
  addRoutes: [],
  btnPermissions: [],
  menuCodesList: []
}

const mutations = {
  SET_BTN_PERMISSIONS: (state, permissions) => {
    state.btnPermissions = permissions
  },
  SetMenuCodesList: (state, menuCodesList) => {
    state.menuCodesList = menuCodesList
  },
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  RESET_ROUTES: (state) => {
    state.addRoutes = []
    state.routes = []
    state.btnPermissions = []
    state.menuCodesList = []
  }
}

const actions = {
  async generateRoutes({ commit }) {
    const permissions = await getPermissionInfos()
    const { btnPermissions, menus: menuCodesList } = genPermissionList(permissions)
    commit('SET_BTN_PERMISSIONS', btnPermissions)
    commit('SetMenuCodesList', menuCodesList)
    return new Promise((resolve, reject) => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, menuCodesList)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
