<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  mounted() {
    console.log('App.vue mounted, starting WebSocket connection...');
    // 在页面加载时自动建立 WebSocket 连接
    this.connectWebSocket();
  },
  methods: {
    ...mapActions('user',['connectWebSocket'])
  }
}
</script>
