<!-- components/BackendUpdateNotification.vue -->
<template>
  <div v-if="showNotification" class="modal-overlay">
    <div class="modal">
      <h3>系统更新提示</h3>
      <p>系统已更新:{{ updateTitle }}，点击下方按钮以获取最新内容。</p>
      <div class="modal-actions">
        <button @click="handleRefresh">立即更新</button>
      </div>
    </div>
  </div>
</template>

<script>
import SYSAPI from "@/api/system";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showNotification: false,
      backendVersion: "",
      updateTitle: "",
    };
  },
  computed: {
    // 使用 Vuex 中的 getters 获取 WebSocket 消息
    ...mapGetters(["receivedMessage"]),
  },
  watch: {
    receivedMessage(newVal, oldVal) {
      console.log("receivedMessage 发生变化:", newVal);
      if (typeof newVal === "string" && newVal.includes("update_message")) {
        this.$message.warning("system update message");
        this.checkBackendVersion();
      }
    },
  },
  methods: {
    handleRefresh() {
      // 刷新页面
      window.location.reload();
      this.showNotification = false;
    },
    // handleVersionUpdate() {
    //   // 检查 localStorage，如果用户未点击过更新，则显示提示
    //   const hasUpdated = localStorage.getItem('hasUpdated');
    //   if (hasUpdated !== 'true') {
    //     this.showNotification = true;
    //   }
    // },
    // 添加定时任务或其他触发逻辑来检查后端版本更新
    checkBackendVersion() {
      // 模拟定时任务或其他逻辑获取后端版本信息
      SYSAPI.getLatesUpdateMsg().then((res) => {
        if (res && res.code === "success") {
          if (res.data) {
            const rep = res.data;
            const version = rep.version;
            const title = rep.title;
            const localVersion = localStorage.getItem("backendVersion"); // 获取本地存储的版本号
            if (!localVersion || version !== localVersion) {
              this.updateTitle = title;
              // 更新本地存储的版本号
              localStorage.setItem("backendVersion", version);
              // 显示更新通知
              this.showNotification = true;
            }
          }
        }
      });
    },
  },
  created() {
    setInterval(() => {
      this.checkBackendVersion();
    }, 60000 * 5);
  },
  beforeDestroy() {},
};
</script>

<style scoped>
/* 模态框的样式 */
.modal-overlay {
  position: fixed; /* 确保覆盖整个视口 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 设置较高的 z-index */
}
.modal {
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
}

.modal h3 {
  margin-top: 0;
}

.modal-actions {
  margin-top: 20px;
}

.modal-actions button {
  background-color: #42b983;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions button:hover {
  background-color: #36a36c;
}
</style>
