import ajax from "./request";

const API = {
  UploadFileUrl: "/api/public/file",
  /**
   * 消息列表
   */

  getMsgList() {
    return ajax.postWithLoading(`/msgCenter/list`);
  },

  doCleanMsg() {
    return ajax.postWithLoading(`/msgCenter/clean`);
  },
  getMsgDetail(data) {
    return ajax.postWithLoading(`/msgCenter/read`, data);
  },
  /**
   * 用户修改密码
   *
   * @param data 修改密码所需的数据对象
   * @returns 无返回值
   */
  userChangePwd(data) {
    return ajax.postWithLoading(`/banner/user/changePwd`, data);
  },

  userAvatarUp(data) {
    return ajax.postWithLoading(`/banner/user/avatar`, data);
  },
  userDetail(data) {
    return ajax.post(`/banner/user/detail`, data);
  },
  /**
   * @description: 查询所有角色
   * @param {data}
   * @return:
   */
  getALlRoles(data) {
    return ajax.postWithLoading(`/role/list`, data);
  },

  /**
   * @description: 获取角色详情
   * @param {data}
   * @return:
   */
  getRoleDetail(data) {
    return ajax.postWithLoading(`/role/info`, data);
  },

  /**
   * @description: 新增角色
   * @param {data}
   * @return:
   */
  addRole(data) {
    return ajax.postWithLoading(`/role/add`, data);
  },

  /**
   * @description: 修改角色
   * @param {data}
   * @return:
   */
  editRole(data) {
    return ajax.postWithLoading(`/role/modify`, data);
  },

  /**
   * @description: 删除角色
   * @param {data}
   * @return:
   */
  delRole(data) {
    return ajax.postWithLoading(`/role/delete`, data);
  },

  /**
   * @description: 新增部门
   * @param {data}
   * @return:
   */
  addDept(data) {
    return ajax.postWithLoading(`/org/dept/add`, data);
  },

  /**
   * @description: 编辑部门
   * @param {data}
   * @return:
   */
  editDept(data) {
    let data1 = {
      id: data.deptId,
      parentId: data.parentId,
      name: data.name,
    };

    return ajax.postWithLoading(`/org/dept/modify`, data1);
  },

  /**
   * @description: 删除部门
   * @param {data}
   * @return:
   */
  delDept(data) {
    return ajax.postWithLoading(`/org/dept/delete`, data);
  },

  /**
   * @description: 获取部门列表
   * @param {data}
   * @return:
   */
  getDeptList(data) {
    return ajax.post(`/org/dept/treeList`, data);
  },

  /**
   * @description: 获取员工列表
   * @param {data}
   * @return:
   */
  getUserList(data) {
    return ajax.postWithLoadingHasError(`/org/user/list`, data);
  },

  /**
   * @description: 创建用户
   * @param {data}
   * @return:
   */
  addUser(data) {
    return ajax.postWithLoading(`/org/user/add`, data);
  },

  /**
   * @description: 修改用户
   * @param {data}
   * @return:
   */
  editUser(data) {
    return ajax.postWithLoading(`/org/user/modify`, data);
  },

  /**
   * @description: 删除用户
   * @param {data}
   * @return:
   */
  delUser(data) {
    return ajax.postWithLoading(`/org/user/delete`, data);
  },

  /**
   * @description: 停用启用用户
   * @param {data}
   * @return:
   */
  frozenUser(data) {
    return ajax.postWithLoading(`/org/user/frozen`, data);
  },

  /**
   * @description:  重置密码
   * @param {data}
   * @return:
   */
  resetPassword(data) {
    return ajax.postWithLoading(`/org/user/resetPwd`, data);
  },

  /**
   * @description:  公共字典类型 (1:性别; 2:保险类型; 3:客户所属区域; 4:客户状态; 5:国家; 6:省市区街; 8:放弃原因; 9:沟通; 10:岗位; 11:公司身份; 12:协会; 13:船关联公司角色; 14:基础设置)
   * @param {data}
   * @return:
   */
  getDicts(data) {
    return ajax.post(`/public/dicts`, data);
  },
  getCompanys(data) {
    return ajax.postWithLoading(`/option/companys`, data);
  },

  getProducts(data) {
    return ajax.postWithLoading(`/option/products`, data);
  },
  getVessels(data) {
    return ajax.postWithLoading(`/option/vessels`, data);
  },
  getCustomers(data) {
    return ajax.postWithLoading(`/option/customers`, data);
  },
  getProvinceCities() {
    return ajax.postWithLoading(`/public/provinces/cities`);
  },

  getUsers(data) {
    return ajax.post(`/option/users`, data);
  },
  getInsuranceList(data) {
    return ajax.postWithLoading(`/option/insurances`, data);
  },
  syncDept() {
    return ajax.getWithLoadingHasError(`/org/syncDept`);
  },
  syncDeptUser() {
    return ajax.getWithLoadingHasError(`/org/syncUser`);
  },
  getMessage(data) {
    const data1 = {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
      userId: 26,
      readFlag: data.readFlag,
    };
    return ajax.post(`/banner/msg/list`, data1);
  },
  clearMsg(data) {
    return ajax.postWithLoading(`/banner/msg/clean`, data);
  },
  getMsgCount(data) {
    return ajax.post(`/banner/msg/count`, data);
  },
  readMsg(data) {
    return ajax.postWithLoading(`/banner/msg/read`, data);
  },
  sendMailCode(data) {
    return ajax.postWithLoading(`/open/sendMail`, data);
  },
  sendMailPwd(data) {
    return ajax.postWithLoading(`/open/sendPwd`, data);
  },
  getWeather(data) {
    return ajax.postWithLoading(`/home/weathers`, data);
  },
  getCheckin() {
    return ajax.getWithLoading(`/home/checkin`);
  },
  getVessel() {
    return ajax.getWithLoadingHasError(`/home/getVessels`);
  },
  getFleets(data) {
    return ajax.postWithLoading(`/option/fleets`, data);
  },
  addFleet(data) {
    return ajax.postWithLoading(`/option/addFleet`, data);
  },
  getLatesUpdateMsg() {
    return ajax.getWithoutLoadingHasError(`/system/update/message`);
  },
  getMessageList(data) {
    return ajax.postWithLoadingHasError(`/system/update/messages`, data);
  },
  saveMessage(data) {
    return ajax.postWithLoadingHasError(`/system/update/message/save`, data);
  },
};

export default API;
