import permission from "./modules/permission"

const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  permissionRoutes: state => state.permission.routes,
  btnPermissions: state => state.permission.btnPermissions,
  receivedMessage: state => {
    console.log('Received Message:',state.user.receivedMessage)
    return state.user.receivedMessage
  },
}
export default getters
