import Vue from 'vue'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'
import Cookies from 'js-cookie'
import '@/icons' // icon
import '@/permission' // permission control
import permission from '@/directive/permission/index' // 权限判断指令
import locale from 'element-ui/lib/locale/lang/en'; // 引入英文语言包
import { startSSEConnection } from './utils/sseClient';

// Vue.use(ElementUI, { locale }); // 设置语言

// 引入echarts库
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts

Vue.use(ElementUI, { size: 'mini',locale })
Vue.use(permission)
Vue.prototype.$cookie = Cookies
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
// 启动 SSE 连接
// startSSEConnection();
