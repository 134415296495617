var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showNotification
    ? _c("div", { staticClass: "modal-overlay" }, [
        _c("div", { staticClass: "modal" }, [
          _c("h3", [_vm._v("系统更新提示")]),
          _c("p", [
            _vm._v(
              "系统已更新:" +
                _vm._s(_vm.updateTitle) +
                "，点击下方按钮以获取最新内容。"
            ),
          ]),
          _c("div", { staticClass: "modal-actions" }, [
            _c("button", { on: { click: _vm.handleRefresh } }, [
              _vm._v("立即更新"),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }