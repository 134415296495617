import ajax from "./request";

/**
 * @description: 登录
 * @param {data}
 * @return:
 */
export function loginFun(data) {
  return ajax.postWithLoading(`/open/login`, data);
}
/**
 * @description: 获取权限
 * @param {data}
 * @return:
 */
export function getPermissionInfos(data) {
  return ajax.post(`/personalCenter/getPermissionTreeDTOs`, data);
}

/**
 * @description: 退出登录
 * @param {data}
 * @return:
 */
export function loginOutFun() {
  return ajax.postWithLoading(`/open/logOut`);
}

/**
 * @description: 激活账号
 * @param {data}
 * @return:
 */
export function activateFun(data) {
  return ajax.postWithLoading(`/rest/operator/changePassword`, data);
}

/**
 * @description: 标签管理-列表
 * @param {data}
 * @return:
 */
export function tagFun(data) {
  return ajax.post(`/rest/tag/listByPage`, data);
}

/**
 * @description: 标签管理-标签类型列表
 * @param {data}
 * @return:
 */
export function tagFunList() {
  return ajax.get(`/rest/tag/listType?code`);
}

/**
 * @description: 标签管理-新增标签类型
 * @param {data}
 * @return:
 */
export function addTagType(data) {
  return ajax.putWithLoading(`/rest/tag/addType`, data);
}

/**
 * @description: 删除标签
 * @param {data}
 * @return:
 */
export function deleteTag(data) {
  return ajax.getWithLoading(`/rest/tag/delete/${data}`);
}

/**
 * @description: 标签管理-新增标签
 * @param {data}
 * @return:
 */
export function addTag(data) {
  return ajax.putWithLoading(`/rest/tag/add`, data);
}

/**
 * @description: 保存、更新标签
 * @param {data}
 * @return:
 */
export function upDataTag(data) {
  return ajax.postWithLoading(`/rest/tag/update`, data);
}

/**
 * @description: 获取标签、下拉框各种数据
 * @param {data}
 * @return:
 */
export function getTagList(data) {
  return ajax.get(`/rest/tag/listTag?typeCode=${data}`);
}

/**
 * @description: 员工列表
 * @param {data}
 * @return:
 */
export function staffFun(data) {
  return ajax.post(`/rest/merchant/list`, data);
}

/**
 * @description: 新增商户
 * @param {data}
 * @return:
 */
export function addTenant(data) {
  return ajax.postWithLoading(`/rest/merchant/add`, data);
}

/**
 * @description: 删除商户
 * @param {data}
 * @return:
 */
export function deleteMerchant(id) {
  return ajax.getWithLoading(`/rest/merchant/delete/${id}`);
}

/**
 * @description: 获取用户信息
 * @param {data}
 * @return:
 */
export function getUserDetail() {
  return ajax.getWithLoading(`/rest/operator/getUserInfo`);
}

/**
 * @description: 门店管理-列表
 * @param {data}
 * @return:
 */
export function shopList(data) {
  return ajax.postWithLoading(`/rest/shop/listByPage`, data);
}

/**
 * @description: 新增门店
 * @param {data}
 * @return:
 */
export function addShop(data) {
  return ajax.postWithLoading(`/rest/shop/add`, data);
}

/**
 * @description: 更新门店
 * @param {data}
 * @return:
 */
export function updateShop(data) {
  return ajax.postWithLoading(`/rest/shop/update`, data);
}

/**
 * @description: 门店详情
 * @param {data}
 * @return:
 */
export function shopDetail(id) {
  return ajax.getWithLoading(`/rest/shop/info/${id}`);
}

/**
 * @description: 门店ID
 * @param {data}
 * @return:
 */
export function shopListArr(data) {
  return ajax.get(`/rest/shop/listByCache`);
}

/**
 * @description: 门店下线、关闭门店
 * @param {data}
 * @return:
 */
export function closeShop(data) {
  return ajax.postWithLoading(`/rest/shop/close?id=${data}`);
}

/**
 * @description: 门店上线、运营门店
 * @param {data}
 * @return:
 */
export function saleShop(data) {
  return ajax.postWithLoading(`/rest/shop/sale?id=${data}`);
}

/**
 * @description: 首页初始化
 * @param {data}
 * @return:
 */
export function homeInit() {
  return ajax.getWithLoading(`/rest/home/index`);
}

/**
 * @description: 注册用户查询接口
 * @param {data}
 * @return:
 */
export function getRegUsers(data) {
  return ajax.postWithLoading(`/rest/home/listUser`, data);
}

/**
 * @description: 获取预授信详情
 * @param {data}
 * @return:
 */
export function getPreauthInfo(data) {
  return ajax.postWithLoading(`/rest/home/listPreauthInfo`, data);
}

/**
 * @description: 清除标签缓存
 * @param {data}
 * @return:
 */
export function clearTagCache() {
  return ajax.getWithLoading(`/rest/tag/clearCache`);
}

/**
 * @description: offer列表
 * @param {data}
 * @return:
 */
export function getOfferList(data) {
  return ajax.post(`/rest/offer/listByPage`, data);
}

/**
 * @description: 打款初始化
 * @param {data}
 * @return:
 */
export function cashTaskinit(offerId) {
  return ajax.get(`/rest/payment/fund/cashTask/init?offerId=${offerId}`);
}

/**
 * @description: 打款提交
 * @param {data}
 * @return:
 */
export function cashTaskCommit(data) {
  return ajax.postWithLoading(`/rest/payment/fund/cashTask/commit`, data);
}

/**
 * @description: 回款计划
 * @param {data}
 * @return:
 */
export function repayPlanList(data) {
  return ajax.postWithLoading(`/rest/repay/plan/list`, data);
}

/**
 * @description: 回款详情
 * @param {data}
 * @return:
 */
export function repayDetail(id) {
  return ajax.getWithLoading(`/rest/repay/detail?profitRepaymentId=${id}`);
}

/**
 * @description: 回款审核
 * @param {data}
 * @return:
 */
export function repayAudit(data) {
  return ajax.postWithLoading(`/rest/repay/audit`, data);
}

export function deleteFile(filePath) {
  return ajax.postWithLoadingHasError(`/public/oss/delete`,{dir:filePath});
}
