
/* eslint-disable */

import {
  Message,
  Loading,
  MessageBox
} from 'element-ui'
import axios from 'axios'

const env = process.env.NODE_ENV;

let Http = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'zh-CN,zh;q=0.9',
  },
  timeout: 30000,
  needLoading: true
});

let loadingInstance;

const transformRequest = [function (data) {
  let ret = ''
  for (let it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  }
  if (/\&$/.test(ret)) ret = ret.slice(0, ret.length - 1);
  return ret;
}];

Http.interceptors.request.use(config => {
  if (config.needLoading) {
    loadingInstance = Loading.service({
      text: config.loadingText || "拼命加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.8)",
    });
  }
  return config;
}, error => {
  return Promise.reject(error)
})

Http.interceptors.response.use(
  response => {   
    loadingInstance && loadingInstance.close();
      // 如果返回的状态码为200，可以正常拿到数据
      if(response.data.code || response.data.responseCode){
          if(response.data.code == '000000' || response.data.responseCode == '000000' ){
              return Promise.resolve(response.data);
          }else{
              MessageBox({
                title: '提示',
                message: response.data.responseMsg || '系统开了个小差，请稍后重试',
              })
              return Promise.reject(response.data.responseMsg); 
          }
      }else{
          return Promise.resolve(response.data);  
      }
  },    
  error => {     
      if (error.response.status) {         
          switch (error.response.status) {                                
              case 401:                    
                  window.location.href = '/login?redirectUrl=' + encodeURIComponent(location.href)
                  break; 
              // 404请求不存在
              case 404:
                  router.replace('/404');
                  break;
              default:
                  MessageBox({
                    title: '提示',
                    message: '加载超时,请重试！',
                  })
                  loadingInstance && loadingInstance.close();
                  break;
          }
          MessageBox({
            title: '提示',
            message: '加载超时,请重试！',
          })
          loadingInstance && loadingInstance.close();
          return Promise.reject()
      }
});

export {
  Http
}
