export function startSSEConnection() {
    if (typeof EventSource !== 'undefined') {
      const eventSource = new EventSource('/api/sse/connect');
  
      eventSource.onopen = () => {
        console.log('SSE 连接已建立');
      };
  
      eventSource.onmessage = (event) => {
        console.log('收到消息：', event.data);
        // 根据需要处理默认消息
      };
  
      eventSource.addEventListener('version_update', (event) => {
        console.log('收到更新消息：', event.data);
        // 触发版本更新事件
        window.dispatchEvent(new CustomEvent('backendVersionUpdated'));
      });
  
      eventSource.onerror = (error) => {
        console.error('SSE 连接出错：', error);
        // 处理错误，必要时重新连接
      };
  
      return eventSource;
    } else {
      console.error('当前浏览器不支持 SSE');
      // 可以考虑使用轮询或其他替代方案
    }
  }