<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permissionRoutes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
    <!-- <el-button
      type="info"
      class="logout"
      icon="el-icon-switch-button"
      title="退出登录"
      :circle="isCollapse"
      round
      @click.native="logout"
      >{{!isCollapse ? '退出登录' : null}}</el-button
    > -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";
import * as API from "@/api/api";

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(["sidebar", "permissionRoutes"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  data() {
    return {
      permissions: [],
      routes: this.$router.options.routes,
    };
  },
  created() {
    // let permissions = JSON.parse(localStorage.getItem("permissions"));
    // let routes = this.$router.options.routes;
    // this.pushPermiss(permissions);
    // this.pushRoutes(routes);
  },
  methods: {
    pushRoutes: function (arr) {
      arr.map((item, index) => {
        item.hasPermission = this.permissions.includes(item.permissionCode);
        if (item.children && item.children.length > 0) {
          this.pushRoutes(item.children);
        }
      });
    },
    pushPermiss: function (arr) {
      arr.map((item) => {
        if (item.children && item.children.length > 0) {
          this.pushPermiss(item.children);
        } else {
          if (item.permissionType < 2) {
            this.permissions.push(item.permissionCode);
          }
        }
        if (item.permissionType < 2) {
          this.permissions.push(item.permissionCode);
        }
      });
    },
    async logout() {
      API.loginOutFun().then((res) => {
        if (res && res.success) {
          this.$cookie.remove("OPERATOR-SESSION");
          localStorage.removeItem("permissions");
          this.$router.push(`/login`);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logout{
  position: absolute;
  left: 0;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  &.is-circle{
    ::v-deep span{
      display: none!important;
    }
  }
}
</style>
