<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="header-right">
      <!-- 消息提示图标 注意样式和布局 -->
      <div class="badge-icon" @click="drawer = true">
        <el-badge :value="unreadMessages" class="message-icon">
          <i class="el-icon-s-comment message-button"></i>
        </el-badge>
      </div>
      <div class="user-info">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="personalData"
              ><span>Profile</span></el-dropdown-item
            >
            <el-dropdown-item @click.native="modifyPassword"
              ><span>Change Password</span></el-dropdown-item
            >
            <el-dropdown-item divided @click.native="logout">
              <span>Logout</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-avatar :src="avatar" class="avatar"></el-avatar>
    </div>
    <el-drawer
      title="Message Center"
      :visible.sync="drawer"
      :with-header="false"
      size="30%"
      :modal="true"
      :append-to-body="true"
      :custom-class="'message-drawer'"
    >
      <div class="message-container">
        <el-button type="text" class="clearUnreadMsg" @click="clearMsg"
          >清除未读</el-button
        >
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="未读消息" name="first">
            <div
              v-for="message in unreadMsgList"
              :key="message.id"
              class="message-item"
            >
              <div class="message-header">
                <h3>{{ message.title }}</h3>
                <span class="message-timestamp">{{ message.occurTime }}</span>
              </div>
              <div class="message-content">
                {{ message.content }}
                <span
                  class="reject-reason"
                  v-if="message.otherParam.auditStatus == 2"
                  @click="showRejectReason(message)"
                  >>>点击查看审批拒绝原因
                </span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全部消息" name="second">
            <div
              v-for="message in allMsgList"
              :key="message.id"
              class="message-item"
            >
              <div class="message-header">
                <h3>{{ message.title }}</h3>
                <span class="message-timestamp">{{ message.occurTime }}</span>
              </div>
              <div class="message-content">
                {{ message.content }}
                <span
                  class="reject-reason"
                  v-if="message.otherParam.auditStatus == 2"
                  @click="showRejectReason(message)"
                  >>>点击查看审批拒绝原因
                </span>
              </div>
              <!-- <div v-html="message.content" class="message-content"></div> -->
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <el-button v-if="hasMore" @click="loadMore" class="load-more"
        >Load More</el-button
      > -->
    </el-drawer>
    <el-dialog
      title="change password"
      :visible.sync="dialogFormVisible"
      append-to-body
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :style="{ borderRadius: '10px' }"
    >
      <el-form :model="form">
        <el-form-item label="Old" :label-width="formLabelWidth">
          <el-input
            v-model="form.oldPwd"
            show-password
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="new" :label-width="formLabelWidth">
          <el-input
            v-model="form.newPwd"
            show-password
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click.native="changePwd">Confirm</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="X ClosingSlip签字审批拒绝原因"
      :visible.sync="rejectReasonFlag"
      append-to-body
      width="30%"
      @close="handleClose"
    >
      <div>
        <p>业务单号: {{ orderNo }}</p>
        <p>拒绝原因:</p>
        <ul>
          <li>{{ reasons }}</li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectReasonFlag = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Profile"
      :visible.sync="dialogFormVisible1"
      append-to-body
      width="45%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :style="{ borderRadius: '20px' }"
    >
      <el-form :model="form1" :label-width="formLabelWidth">
        <el-form-item label="Avatar" class="avatar-upload-item">
          <div class="avatar-upload-container">
            <el-avatar :size="100" fit="fill" :src="form1.faceUrl"></el-avatar>
            <el-upload
              class="upload-demo"
              :action="UploadFileUrl"
              :on-success="handleSuccess"
            >
              <el-button size="small" type="primary">click upload</el-button>
              <div slot="tip" class="el-upload__tip">
                only support jpg/png，and less than 500kb
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="No." class="form-item">
          <span>{{ form1.code }}</span>
        </el-form-item>
        <el-form-item label="Name" class="form-item">
          <span>{{ form1.name }}</span>
        </el-form-item>
        <el-form-item label="Email" class="form-item">
          <span>{{ form1.email }}</span>
        </el-form-item>
        <el-form-item label="Tel" class="form-item">
          <span>{{ form1.phone }}</span>
        </el-form-item>
        <el-form-item label="Depart" class="form-item">
          <div>
            <el-tag
              v-for="(dept, index) in form1.depts"
              :key="index"
              type="success"
              class="tag-item"
            >
              {{ dept.name }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="Role" class="form-item">
          <div>
            <el-tag
              v-for="(role, index) in form1.roles"
              :key="index"
              type="warning"
              class="tag-item"
            >
              {{ role.name }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">Close</el-button>
      </div>
    </el-dialog>
    <!-- 添加消息中心抽屉 -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import axios from "axios";
import API from "@/api/system";

export default {
  data() {
    return {
      rejectReasonFlag: false,
      reasons: [],
      orderNo: "",
      allMsgList: [],
      avatar: "",
      userName: "",
      activeName: "first",
      UploadFileUrl: API.UploadFileUrl,
      loading: false, // 加载状态
      unreadMessages: 0,
      drawer: false,
      currentPage: 1,
      pageSize: 10,
      visibleMessages: [],
      hasMore: true,
      dialogVisible: false,
      loadStep: 5, // 每次加载的消息数
      drawerHeight: "300px", // 默认高度
      dialogFormVisible: false,
      dialogFormVisible1: false,
      form: {
        oldPwd: "",
        newPwd: "",
      },
      form1: {
        code: "",
        name: "",
        email: "",
        phone: "",
        faceUrl: "",
      },
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
      },
      formLabelWidth: "100px",

      unreadMsgList: [],
    };
  },
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar"]),
    isShow() {
      const { meta } = this.$route;
      if (meta.showNavBar && meta.showNavBar == -1) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getlist();
    this.getMessageCount();
    this.loadMore(); // 初始加载一些消息
    this.updateDrawerHeight(); // 组件挂载时更新高度
    window.addEventListener("resize", this.updateDrawerHeight); // 窗口尺寸改变时更新高度
    this.userName = this.$cookie.get("name");
    this.avatar = this.$cookie.get("avatar");
    API.userDetail({ name: this.$cookie.get("userName") }).then((res) => {
      const userDO = res;
      console.log(userDO);
      this.form1 = { ...this.form1, ...userDO };
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDrawerHeight); // 组件销毁前移除事件监听
  },
  methods: {
    handleClose() {
      // 弹框关闭时的回调函数
      this.rejectReasonFlag = false;
    },
    //打开拒绝原因
    async showRejectReason(msg) {
      const param = { messageId: msg.id };
      let res = await API.getMsgDetail(param);
      this.reasons = res.auditMsg || "";
      this.orderNo = msg.otherParam.orderCode;

      this.rejectReasonFlag = true;
    },
    async clearMsg() {
      let res = await API.doCleanMsg();
      this.getlist();
      this.$message.success("清空成功");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async getlist() {
      let res = await API.getMsgList();
      this.unreadMsgList = res.unReadMessages;
      this.allMsgList = res.readMessages;
    },
    handlePreview(file) {
      console.log(file);
    },
    async clearMsgs() {
      try {
        const data = {};
        const res = await API.clearMsg(data);
        this.currentPage = 1;
        this.refreshMessages();
        this.getMessageCount();
      } catch (e) {
        console.log(e);
      }
    },
    async handleReadMsg(msg) {
      if (msg.readStatus === 1) {
        return;
      }
      const data = {
        id: msg.id,
      };
      await API.readMsg(data);
      this.refreshMessages();
      this.getMessageCount();
    },
    async getMessageCount() {
      const data = {};
      const qty = await API.getMsgCount(data);
      this.unreadMessages = qty || 0;
    },
    modifyPassword() {
      this.form = {
        oldPwd: "",
        newPwd: "",
      };
      this.dialogFormVisible = true;
    },
    updateDrawerHeight() {
      const desiredHeight = window.innerHeight * 0.8; // 计算目标高度为视窗高度的80%
      this.drawerHeight = `${desiredHeight}px`;
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      });
    },
    async loadMore() {
      if (!this.hasMore) return;

      try {
        const data = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        };
        const response = await API.getMessage(data);
        if (response && response.length > 0) {
          this.visibleMessages.push(...response);
          this.currentPage++;
          if (response.length == this.pageSize) {
            this.hasMore = true;
          } else {
            this.hasMore = false;
          }
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        console.error("Failed to fetch messages:", error);
        this.hasMore = false;
      }
    },
    async refreshMessages() {
      this.hasMore = true;
      console.log(this.hasMore);
      try {
        this.loading = true;
        this.loadMore();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Failed to refresh messages:", error);
      }
    },
    changePwd() {
      const data = { ...this.form };
      API.userChangePwd(data)
        .then((res) => {
          this.$message.success("修改密码完成");
          this.dialogFormVisible = false;
        })
        .catch((err) => {});
    },
    personalData() {
      this.dialogFormVisible1 = true;
    },
    handleSuccess(response, file) {
      const uid = file.uid;
      console.log(response.data);
      this.form1.faceUrl = response.data;
      this.avatar = response.data;
      this.$cookie.set("avatar", response.data);
      const data = {
        name: this.$cookie.get("userName"),
        avatar: response.data,
      };
      API.userAvatarUp(data).then((res) => {
        this.$message.success("头像更新成功");
      });
    },
  },
  async created() {},
};
</script>
<style lang="scss" scoped>
.clearUnreadMsg {
  text-align: right;
}
.navbar {
  height: 50px;
  width: 100%;
  display: flex;
  overflow: hidden;
  z-index: 100;
  background: #e5eefd;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-right: 10px;

  .hamburger-container {
    flex: 1;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    flex: 10;
  }

  .header-left {
    flex: 1;
  }

  .badge-icon {
    position: relative;
    margin-right: 20px;
  }

  .badge-icon .el-badge__content {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-color: #f56c6c; /* 设置红色背景 */
    color: #fff;
    font-size: 12px;
    min-width: 18px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #fff; /* 添加白色边框 */
  }
  ::v-deep .el-badge__content.is-fixed {
    top: 10px;
  }

  .message-button {
    font-size: 24px; /* 调整信封图标的尺寸 */
    color: black; /* 将信封图标设置为黑色 */
  }

  .avatar {
    margin-left: 15px;
  }

  .user-info {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .user-role {
    font-weight: bold;
    margin-right: 10px; /* 调整间距使元素不拥挤 */
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #000;
    font-weight: bold;
  }
}
.header-right {
  flex: 20;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.avatar {
  margin-right: 15px;
}
.role {
  line-height: 42px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-role {
  font-weight: bold;
  margin-right: 20px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.red-dot {
  position: absolute;
  left: 6px;
  top: 6px;
  display: inline-block;
  background-color: #f56c6c; /* 设置红色背景 */
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.message-container {
  display: flex;
  flex-direction: column;
  max-height: 1000px; /* 可滚动区域的最大高度 */
  overflow-y: auto; /* 添加滚动条 */
  padding: 10px;
  .btn-wrap {
    padding-right: 20px;
  }
}

.message-card {
  margin-bottom: 20px; /* 卡片间距 */
}

.load-more {
  margin-top: 10px; /* 加载更多按钮间距 */
  width: 100%; /* 按钮宽度 */
}
.message-time {
  font-size: 0.8em; /* 使字体稍小 */
  color: #888; /* 使颜色更淡 */
  margin-left: 10px; /* 在名称和时间之间添加一些间隙 */
}
.message-status {
  font-size: 0.7em;
  padding: 2px 5px;
  border-radius: 10px;
  color: white;
  margin-left: 10px;
}

.read {
  background-color: #4caf50; /* 已读为绿色 */
}

.unread {
  background-color: #ff5722; /* 未读为红色 */
}
.message-list {
  width: 100%;
  max-width: 600px; /* 可根据实际情况调整 */
  margin: 20px;
  flex: 1;
}

.message-item {
  cursor: pointer;
  position: relative;
  border: 1px solid #ebebeb;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.message-timestamp {
  font-size: 14px;
  color: #888;
  margin-left: auto; /* 这将确保时间戳始终在最右边 */
  white-space: nowrap; /* 阻止时间戳换行 */
}

.message-item:last-child {
}

.message-item p {
  margin: 0;
  font-size: 14px;
  color: #666;
}
.message-header {
  display: flex;
  justify-content: space-between; /* 确保标题和时间戳分布在两端 */
  align-items: center;
  margin-bottom: 8px;
}

.message-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  flex: 1; /* 确保标题占据多余空间，时间靠右 */
}
/* 消息标题和内容的内部边距 */
.message-header h3,
.message-item p {
  margin-top: 0;
  margin-bottom: 4px; /* 减少内部元素的边距 */
}

.title-refresh-button {
  padding: 0;
  border: none;
  background: none;
  color: #409eff; /* 设置图标颜色，可根据主题调整 */
  margin-inline: 10px;
  ::v-deep {
    .el-icon-refresh {
      font-size: 20px;
    }
  }
}
.reject-reason {
  color: #409eff;
}

.title-refresh-button:hover {
  background: transparent; /* 鼠标悬停时保持透明背景 */
}
.drawer-title {
  font-weight: bold; /* 加粗标题文字 */
  font-size: 16px; /* 可以调整字号以适应设计 */
  color: #333;
}
.tag-item {
  margin-right: 5px; /* 标签之间的间距 */
}
.el-form-item {
  margin-bottom: 20px; /* 统一表单项之间的间距 */
}
.avatar-upload-item {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.avatar-upload-container {
  display: flex;
  align-items: center; /* 子元素垂直居中对齐 */
}

.el-avatar {
  margin-right: 20px; /* 头像和上传按钮之间的间距 */
}

.el-upload {
  display: flex;
  flex-direction: column; /* 如果上传按钮和提示需要垂直排列 */
}
span {
  margin-left: 20px; /* 与标签保持一致的间距 */
}
</style>

<style scoped>
.el-dialog {
  background-color: #e6f7ff; /* 淡蓝色背景 */
  color: #333; /* 确保文本颜色适合淡蓝色背景 */
}
</style>
